import React, { useEffect, useState } from 'react';
import './NavMenu.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';

const Navmenu = () => {

    const [scroll, Setscroll] = useState(false)
    const [open, setOpen] = useState(false)

    const handleNavClick = () => {
        setOpen(false)
    }

  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      Setscroll(window.scrollY > 50)
    })
  },[]);

  return (
    <Navbar expand="lg" className={`bg-body-tertiary py-0 position-absolute w-100 ${scroll ? 'fixed' : ''}`}>
      <Container fluid>
              <Navbar.Brand href="#home"><img src="./images/logo.png" alt="" /></Navbar.Brand>
              <div className={`navbar-toggler ${!open ? '' : 'active'} flex-column border-0 `} onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Navbar.Collapse className={`mb-3 mb-lg-0 ps-3 ps-lg-0 ${!open ? 'd-none' : 'd-block'}`} id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer onClick={handleNavClick} to="/">
                          <Nav.Link className='me-3'>Home</Nav.Link>
            </LinkContainer>
                      <LinkContainer onClick={handleNavClick} to="/aboutus">
                <Nav.Link className='me-3'>About Us</Nav.Link>
            </LinkContainer>
                      <LinkContainer onClick={handleNavClick} to="/services">
                <Nav.Link className='me-3'>Services</Nav.Link>
            </LinkContainer>
                      <LinkContainer onClick={handleNavClick} to="/tracking">
                <Nav.Link className='me-3'>Tracking</Nav.Link>
            </LinkContainer>
                      <LinkContainer onClick={handleNavClick} to="/faqs">
                <Nav.Link className='me-3'>FAQs</Nav.Link>
                      </LinkContainer>
                      <LinkContainer onClick={handleNavClick} to="/contactus">
                <Nav.Link className='me-3'>Contact Us</Nav.Link>
            </LinkContainer>
                      <LinkContainer onClick={handleNavClick}  className='themebtn rounded px-3 py-2 text-white' to="/signup">
              <Nav.Link className='me-3'>Signup <div className="icon-box">
              <i class='bx bx-log-in-circle bx-tada' style={{fontSize:'20px'}} ></i>
                    </div></Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


export default Navmenu;
