import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Tracking = () => {
  return (
    <>
        <section className='comingsoon'>
            <Container>
                <Row className='justify-content-center'>
                 <Col md={8} className='text-center'>
                    <div className="ty_image position-relative">
                        <img src="./images/kangaroo.svg" alt="" />
                    </div>
                          <h1 className='mb-4'>Coming Soon</h1>
                    <Link to='/' className='themebtn mt-4 mt-md-0 rounded d-inline-flex'>Back To Home
                            <div className="icon-box">
                            <i className='bx bx-chevron-right bx-fade-left' ></i>
                    </div>
                    </Link>
                 </Col>
               </Row>
            </Container>
        </section>
    </>
  )
}

export default Tracking