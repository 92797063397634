import React, { useState } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JamboHeader } from '../Common/JamboHeader';
import MainHeading from '../Common/MainHeading';
import { API_SERVER } from '../../Util';

export const Contactus = () => {
    const Token = "5rdsfjhdsjk454k5kj4gfjkdgkj";
    const [formDetails, setFormDetails] = useState({
        Token: Token,
        Name: "",
        Email: "",
        Subject: "",
        MobileNo: "",
        Message: "",
    })

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        // Remove characters that are not numbers or specific special characters
        value = value.replace(/[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
        setFormDetails({ ...formDetails, MobileNo: value });
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormDetails(prv => ({ ...prv, [name]: value }))
    }

    const handleClick = async () => {
        console.log(formDetails, 'pppppppppppppppp')
        if (formDetails.Name === '') {
            toast.error("Name is Required")
            return;
        } else if (formDetails.Subject === '') {
            toast.error("Subject is Required")
            return;
        
        } else if (formDetails.Message === '') {
            toast.error("Message is Required")
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       

        // Validate email and mobile number
        if (formDetails.Email !== '' && !emailRegex.test(formDetails.Email)) {
            toast.error('Invalid email format');
            return;
        }

        try {
            setLoader(true)
            const handleSendApi = await fetch(`${API_SERVER}/api/ContactUs/ContactUs`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formDetails)
             });

            const res = await handleSendApi.json();
            if (res.status === "SUCCESS") {
                setLoader(false)
                navigate('/thankyou?contacting');
                return;
            }  
            else if (res.status === "ERROR") {
                setLoader(false)
                toast.error(res.message)
                return;
            }

        } catch (error) {
            toast.error('Some error occured, Please try again later.');
            setLoader(false)
        }  
    };
   

    return (
        <>
            <Helmet>
                <title>Contact us | Kangaroo Express</title>
                <meta name="description" content=" If you have any doubts or questions regarding our service or any further queries, contact us today to get the best solutions from experts. Reach out today." />
            </Helmet>

            <JamboHeader pagename='Contact Us' />
            <section className='contact_us'>

                <Container>
                    <Row>
                        <Col md={4}>
                            <Card className='p-2 ps-3 py-3 light-bg border-0 box-shadow mb-4'>
                                <div className='d-flex flex-row align-items-center'>
                                    <div className='cntct_icon d-flex justify-content-center align-items-center me-3'>
                                        <i class='bx bx-buildings'></i>
                                    </div>
                                    <h5 className='m-0'>Head Office</h5>
                                </div>
                                <div className='mt-3 d-flex gap-2 hq'>
                                    <i class='bx bx-map'></i>
                                    <p>3062 W Missionwood Circle, Miramar, Florida, 33126</p>
                                </div>
                                <div className='mt-3 d-flex gap-2 hq'>
                                    <i class='bx bx-mail-send' ></i>
                                    <p><a href="mailto:Info@kanagrooexpress.shop">Info@kanagrooexpress.shop</a></p>
                                </div>
                                {/*<div className='mt-3 d-flex gap-2 hq'>*/}
                                {/*    <i class='bx bx-phone-call' ></i>*/}
                                {/*    <p><a href="tell:869-665-4600">869-665-4600</a></p>*/}
                                {/*</div>*/}
                            </Card>                           
                        </Col>
                        <Col md={8}>
                            <MainHeading heading='Drop us a Query' />
                            <p>We are committed to improving our products and services based on your valuable feedback.</p>
                            <Row>
                                <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Enter Your Name"
                                        className="mb-3"
                                    >
                                        <Form.Control onChange={handleChange} name='Name' type="text" placeholder="John" />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Enter Your Email"
                                        className="mb-3"
                                    >
                                        <Form.Control type="email" onChange={handleChange} name='Email' placeholder="name@example.com" />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Enter Subject"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" onChange={handleChange} name='Subject' placeholder="name@example.com" />
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Enter phone number"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" onChange={handlePhoneChange} value={formDetails.MobileNo} name='MobileNo' placeholder="Enter phone number" />
                                    </FloatingLabel>
                                </Col>
                                {/*<Col md={6}> */}
                                {/*    <PhoneInput*/}
                                {/*        international*/}
                                {/*        defaultCountry="KN"*/}

                                {/*        value={formDetails.MobileNo}*/}
                                {/*        onChange={handlePhoneChange}*/}
                      
                                {/*        placeholder="Enter phone number"*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                <Col>
                                    <FloatingLabel controlId="floatingTextarea2" label="Message">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a message here"
                                            onChange={handleChange}
                                            name='Message'
                                            style={{ minHeight: '100px' }}
                                        />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                            {
                                !loader ?
                                    <Button onClick={(e) => handleClick(e)} className='themebtn mt-3'>Send Query
                                        <div className="icon-box">
                                            <i class='bx bx-send bx-flashing' style={{ fontSize: '18px' }}></i>
                                        </div>
                                    </Button> : <Button disabled style={{ cursor: 'not-allowed' }} className='themebtn mt-3 '>Sending mail....
                                        <div className="icon-box">
                                            <i class='bx bx-send bx-flashing' style={{ fontSize: '18px' }}></i>
                                        </div>
                                    </Button>
                            }
                           
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='light-bg contact_us'>
                <Container>
                    <MainHeading heading='Kangaroo Express' center={true} />
                    <Row className='mt-4'>
                        <Col md={4} className='mb-3 mb-md-0'>
                            <Card className='p-2 ps-3 h-100' style={{ border: '1px dashed var(--primary)' }}>
                                <h5 className='text-theme'>St Kitts</h5>
                                <div className='mb-3 d-flex gap-2 hq align-items-end'>
                                    <i className='bx bxs-map bx-tada' ></i>
                                    <p>Rosemary, Lane Basseterre, St Kitts</p>
                                </div>
                                <div className='mb-3 d-flex gap-2 hq'>
                                    <i class='bx bx-mail-send' ></i>
                                    <p><a href="mailto:Skb@kanagarooexpress">Skb@kanagarooexpress</a></p>
                                </div>
                                <div className='d-flex gap-2 hq'>
                                    <i class='bx bx-phone-call' ></i>
                                    <p><a href="tell:869-665-4600">869-665-4600</a></p>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4} className='mb-3 mb-md-0'>
                            <Card className='p-2 ps-3 h-100' style={{ border: '1px dashed var(--primary)' }}>
                                <h5 className='text-theme'>Kenya</h5>
                                <div className='mb-3 d-flex gap-2 hq'>
                                    <i className='bx bxs-map bx-tada' ></i>
                                    <p>Fedha Plaza, Mpaka Road, Westlands, Nairobi, Kenya</p>
                                </div>
                                {/* <div className='mb-3 d-flex gap-2 hq'>
                                    <i class='bx bx-mail-send' ></i>
                                    <p><a href="mailto:Skb@kanagarooexpress">Skb@kanagarooexpress</a></p>
                                </div> */}
                                <div className='d-flex gap-2 hq'>
                                    <i class='bx bx-phone-call' ></i>
                                    <p><a href="tell:+254797491743">+254 797 491 743</a></p>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4} className='mb-3 mb-md-0'>
                            <Card className='p-2 ps-3 h-100' style={{ border: '1px dashed var(--primary)' }}>
                                <div className='d-flex flex-row align-items-center'>

                                    <h5 className='m-0 text-theme'>Coming soon to</h5>
                                </div>
                                <div className='mt-3 d-flex gap-2 hq'>
                                    <ul>
                                        <li>Dominican Republic</li>
                                        <li>St. Maartin</li>
                                        <li>Grenada</li>                                        
                                    </ul>
                                    <ul>
                                        <li>Guyana GY</li>
                                        <li>St. Lucia</li>
                                    </ul>
                                </div>
                                
                            </Card>
                        </Col>
                        <Col style={{ display: 'none' }} md={4} className='mb-3 mb-md-0'>
                            <Card className='p-2 ps-3' style={{ border: '1px dashed var(--primary)' }}>
                                <div className='d-flex flex-row align-items-center'>
                                    <div className='cntct_icon d-flex align-items-center me-3'>
                                        <i class='bx bx-buildings'></i>
                                    </div>
                                    <h6 className='m-0'>Rosemary Lane Basseterre St Kitts</h6>
                                </div>
                                <div className='mt-3 d-flex gap-2 hq'>
                                    <i class='bx bx-mail-send' ></i>
                                    <p><a href="mailto:Rosemary Lane Basseterre St Kitts">Rosemary Lane Basseterre St Kitts</a></p>
                                </div>
                                <div className='mt-3 d-flex gap-2 hq'>
                                    <i class='bx bx-phone-call' ></i>
                                    <p><a href="tell:869-665-4600">869-665-4600</a></p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

          
        </>
    )
}
